import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import saveAs from 'file-saver';
import { JSEncrypt } from 'jsencrypt'
import aes from 'crypto-js/aes'
import { languageSwitchItem } from '../actions/locales'
import { cipher } from '../actions/stringEncodersFuncs'
import { createSession, sendInputData, sendDSData, sendSessionMetaData, sendSessionOption, verifyDSData, getVerifiedData, getQR,
		    sendKeyContainer, createDS, getDSResult, deleteSession, sendTokenPath, createMobileDS, getMobileDSData, getCreateMobileDSResult,
		    createVtcoDs, premissionVtco, convertToAttached, convertToDetached, premission2fVtco, getDSStatus } from '../actions/api'
import { setInputFileForDS, setInputDescription, clearInputFileForDS, clearInputDescription,
			setDSData, clearDSData, setPrevisionDsFile, clearPrevisionDsFile, setDSEncodingType, setCreateDSAddToExistExpand, setQRList, setCleanUpForm,
			setVerifyResultsForAddToExist, setQRVerifyResultsForAddToExist, clearVerifyResultsForAddToExist, clearQRVerifyResultsForAddToExist, clearQRList,
			setTmpPin, setTmpTwoFactorCode, setErrorData, setShowErrorPopup } from '../actions/localStates'
import i18next from 'i18next'
import { bindActionCreators } from 'redux';

import CreateTextDSContainer from './CreateTextDSContainer'

import QRPopup from '../components/body/QRPopup'
import PinPopup from '../components/body/PinPopup'
import TwoFactorConfirmPopup from '../components/body/TwoFactorConfirmPopup'

import Dropzone from 'react-dropzone';

const dropzoneRef = createRef();
const dropzoneDSRef = createRef();

class CreateDSAddToExistContainer extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
			baseUrl: "https://local.cipher.kiev.ua:9090/api/v1/ticket/",
      		uuid: null,
      		isLoading: false,
      		fileForDSDescription: this.props.createDSDefaultState.fileForDSDescription,
      		blobData: null,
      		downloadIsAvailable: true,
      		inputTextValue: "",
      		responseTextValue: "",
      		settedToken: null,
      		collapseCreateDSShow: "",
	    	showPopup: false,
	    	displayedData: null,
	    	qrForFiles: this.props.createDSDefaultState.qrList || [],
	    	showPinPopup: false,
      		showVtcoConfirmPopup: false,
      		numberOfPinInput: 0,
			needToReEnterPin: false,
			successCreation: false
	    }

	    this.onChangeFile = this.onChangeFile.bind(this)
	    this.onChangePrevisionDsFile = this.onChangePrevisionDsFile.bind(this)
	    this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
	    this.withToken = this.withToken.bind(this)
	    this.createMobileDS = this.createMobileDS.bind(this)

	    this.showDsVerifyResults = this.showDsVerifyResults.bind(this);
	    this.handleShowQR = this.handleShowQR.bind(this);

	    this.createQRData = this.createQRData.bind(this);
		this.getQR = this.getQR.bind(this);

		this.handleOnDrop = this.handleOnDrop.bind(this);
	    this.handleOnDropDS = this.handleOnDropDS.bind(this);

	    this.handleShowPin = this.handleShowPin.bind(this);
		this.handleCreateDSUsingPin = this.handleCreateDSUsingPin.bind(this);
	}

	handleShowQR (i) {
		var data;
        document.activeElement.blur();

        if (this.state.displayedData === null) {
			data = {
				fileName: this.props.createDSDefaultState.fileForDSName === null ? this.props.createDSDefaultState.fileForDSName : this.props.createDSDefaultState.previsionDsFileName,
				qrLink: this.props.createDSDefaultState.verifyResultsForQR[i]
			}

			this.setState({
	        	displayedData: data
	        })
		} else {
			this.setState({
	        	displayedData: null
	        })
		}

        this.setState({
            showPopup: !this.state.showPopup
        });
    }

	expandCreateDS() {
		this.props.actions.setCreateDSAddToExistExpand(this.props.expandedCardsReducer.createDSAddToExistExpanded)
		this.props.expandedCardsReducer.createDSAddToExistExpanded ? this.setState({collapseCreateDSShow: "card-body collapse"}) : this.setState({collapseCreateDSShow: "card-body collapse show"})
	}

	buildFileInputSelector(){
		const fileInput = document.getElementById('fileInputUpload');
		return fileInput;
	}

	buildFileInputDescription(){
		const fileDescription = document.getElementById('dscMetaDataFileForDs');
		return fileDescription;
	}

	buildPrevisionDsInput(){
		const filePrevisionDs = document.getElementById('fileInputPrevisionDsUpload');
		return filePrevisionDs;
	}

	componentDidMount() {
		var dropFile = document.getElementById('dropFile');
		var dropDS = document.getElementById('dropDS');

		this.fileInput = this.buildFileInputSelector();
		this.filePrevisionDs = this.buildPrevisionDsInput();
		this.fileDescription = this.buildFileInputDescription();
		if (this.props.createDSDefaultState.fileForDSDescription.length > 0) {
			this.fileDescription.value = this.props.createDSDefaultState.fileForDSDescription
		}
		this.props.expandedCardsReducer.createDSAddToExistExpanded ? this.setState({collapseCreateDSShow: "card-body collapse show"}) : this.setState({collapseCreateDSShow: "card-body collapse"})
		
		if (dropFile !== null) {
			dropFile.addEventListener('dragenter', function(event) {
				dropFile.style.border = "2px solid steelblue";
			}, false);

			dropFile.addEventListener('dragover', function(event) {
				dropFile.style.border = "2px solid steelblue";
			}, false);

			dropFile.addEventListener('dragleave', function(event) {
				dropFile.style.border = "";
			}, false);
		}

		if (dropDS !== null) {
			dropDS.addEventListener('dragenter', function(event) {
				dropDS.style.border = "2px solid steelblue";
			}, false);

			dropDS.addEventListener('dragover', function(event) {
				dropDS.style.border = "2px solid steelblue";
			}, false);

			dropDS.addEventListener('dragleave', function(event) {
				dropDS.style.border = "";
			}, false);
		}

		this.setState({"successCreation": false})
	}

	componentDidUpdate(prevProps) {
		var dropFile = document.getElementById('dropFile');
		var dropDS = document.getElementById('dropDS');

		if (prevProps.duplicateSign !== this.props.defaultState.duplicateSign) {
			this.fileInput = this.buildFileInputSelector();
			this.filePrevisionDs = this.buildPrevisionDsInput();
			this.fileDescription = this.buildFileInputDescription();
		}

		if (prevProps.fileForDSSelected !== this.props.createDSDefaultState.fileForDSSelected) {
			if (dropFile !== null) {
				dropFile.addEventListener('dragenter', function(event) {
					dropFile.style.border = "2px solid steelblue";
				}, false);

				dropFile.addEventListener('dragover', function(event) {
					dropFile.style.border = "2px solid steelblue";
				}, false);

				dropFile.addEventListener('dragleave', function(event) {
					dropFile.style.border = "";
				}, false);
			}
		}

		if (prevProps.previsionDsFileSelected !== this.props.createDSDefaultState.previsionDsFileSelected) {
			if (dropDS !== null) {
				dropDS.addEventListener('dragenter', function(event) {
					dropDS.style.border = "2px solid steelblue";
				}, false);

				dropDS.addEventListener('dragover', function(event) {
					dropDS.style.border = "2px solid steelblue";
				}, false);

				dropDS.addEventListener('dragleave', function(event) {
					dropDS.style.border = "";
				}, false);
			}
		}
	}

	handleFileInput(e) {
	    e.preventDefault();
	    this.fileInput.value = null;
	    this.fileInput.click()
	    document.activeElement.blur()
	}

	handlePrevisionDsFile(e) {
	    e.preventDefault();
	    this.filePrevisionDs.value = null;
	    this.filePrevisionDs.click()
	    document.activeElement.blur()
	}

	handleDescriptionChange(e) {
		this.props.actions.setInputDescription(e.target.value)
	}

	handleShowPin () {
    	this.setState({
            showPinPopup: !this.state.showPinPopup
        });
    }

    handleCreateDSUsingPin () {
    	this.setState({showPinPopup: false})
    	this.createDS();
    }

    createDSWithSigns () {
    	if (this.props.pkLocalState.showPinInput) {
    		this.handleShowPin()
    	} else {
    		this.createDS();
    	} 
    }

	onChangeFile(e) {
		if (e.target.files[0] !== undefined && ((e.target.files[0].size || e.target.files[0].fileSize) < 104857601)) {
			this.props.actions.setInputFileForDS(e.target.files[0])
			this.fileDescription.value = e.target.files[0].name
			this.setState({fileForDSDescription: e.target.files[0].name})
			this.props.actions.clearDSData()
			this.props.actions.clearVerifyResultsForAddToExist()
			this.props.actions.clearQRVerifyResultsForAddToExist()
		} else {
			alert(i18next.t("maxFileSizeTitle"))
		}

		this.setState({"successCreation": false})
	}

	onChangePrevisionDsFile(e) {
		if (e.target.files[0] !== undefined && ((e.target.files[0].size || e.target.files[0].fileSize) < 104857601)) {
			this.props.actions.setPrevisionDsFile(e.target.files[0])
			this.props.actions.clearDSData()
			this.props.actions.clearVerifyResultsForAddToExist()
			this.props.actions.clearQRVerifyResultsForAddToExist()
		} else {
			alert(i18next.t("maxFileSizeTitle"))
		}

		this.setState({"successCreation": false})
	}

	handleOnDrop (files) {
		if (files[0] !== undefined && (files[0].size < 104857601)) {
			this.props.actions.setInputFileForDS(files[0])
			this.fileDescription.value = files[0].name
			this.setState({fileForDSDescription: files[0].name})
			this.props.actions.clearDSData()
			this.props.actions.clearVerifyResultsForAddToExist()
			this.props.actions.clearQRVerifyResultsForAddToExist()
		}

		this.setState({"successCreation": false})
	}

	handleOnDropDS (files) {
		if (files[0] !== undefined && (files[0].size < 104857601)) {
			this.props.actions.setPrevisionDsFile(files[0])
			this.props.actions.clearDSData()
			this.props.actions.clearVerifyResultsForAddToExist()
			this.props.actions.clearQRVerifyResultsForAddToExist()
		}

		this.setState({"successCreation": false})
	}

	async getQR (data) {
    	var _this = this, result;
		result = await _this.props.actions.getQR(_this.props.base.qrCodeUrl, data, _this.props.base.token)
		var objectURL = URL.createObjectURL(result);
		return objectURL;
	}

	createQRData (verifyResults) {
		var _this = this;

		if (!this.props.defaultState.generateQR) {
			return;
		}
		
		var str = "", tempData, fileName = "";

		async function processArray(array) {
			var qrFiles = _this.state.qrForFiles
			for (const item of array) {
				fileName = _this.props.createDSDefaultState.fileForDSName === null ? _this.props.createDSDefaultState.fileForDSName : _this.props.createDSDefaultState.previsionDsFileName
				str += i18next.t("fileName") + fileName + "\n";

				str += (item.signerInfo.extensionsCertificateInfo.value.isPrivateKeyOnQSCD.value === "true" && item.signerInfo.extensionsCertificateInfo.value.isQualified.value === "true") ? i18next.t("ds:qualifiedTitle") + "\n" : i18next.t("ds:unqualifiedTitle") + "\n"

				if (item.signerInfo.ownerCertificateInfo) {
					if (item.signerInfo.ownerCertificateInfo.value.ownerFullName.value) {
						str += i18next.t("ds:signer") + item.signerInfo.ownerCertificateInfo.value.ownerFullName.value + "\n";
					}

					if (item.signerInfo.ownerCertificateInfo.value.ownerPosition.value) {
						str += i18next.t("position") + item.signerInfo.ownerCertificateInfo.value.ownerPosition.value + "\n"
					}

					if (item.signerInfo.ownerCertificateInfo.value.ownerOrganization.value) {
						str += i18next.t("organization") + item.signerInfo.ownerCertificateInfo.value.ownerOrganization.value + "\n"
					}

					if (item.signerInfo.extensionsCertificateInfo.value.personalData.value.drfou.value) {
						str += i18next.t("ds:drfou") + item.signerInfo.extensionsCertificateInfo.value.personalData.value.drfou.value + "\n"
					}

					if (item.signerInfo.extensionsCertificateInfo.value.personalData.value.edrpou.value) {
						str += i18next.t("ds:edrpou") + item.signerInfo.extensionsCertificateInfo.value.personalData.value.edrpou.value + "\n"
					}
					
					if (item.signerInfo.extensionsCertificateInfo.value.personalData.value.eddrun.value) {
						str += i18next.t("ds:eddrun") + item.signerInfo.extensionsCertificateInfo.value.personalData.value.eddrun.value + "\n"
					}

					if (item.signerInfo.issuerCertificateInfo.issuerOrganization) {
						str += i18next.t("issuerOrganization") + item.signerInfo.issuerCertificateInfo.issuerOrganization + "\n"
					}
					
					if (item.signerInfo.certificateSerialNumber.value) {
						str += i18next.t("certificateSerialNumberTitle") + item.signerInfo.certificateSerialNumber.value.replace(/\s/g, "") + "\n"
					}

					str += i18next.t("ds:ca") + item.signerInfo.issuerCertificateInfo.value.issuerFullName.value + "\n"
				}

				if (item.signingDateTime !== undefined && item.signingDateTime.length > 0) {
			    	str += i18next.t("ds:dsDate") + _this.formatDate(item.signingDateTime) + "\n"
			    }
			    
			    if (item.contentTimestamp !== undefined) {
			    	str += _this.constructTimestampDetails(item.contentTimestamp, "content", true) + "\n"
			    }

		    	if (item.signatureTimestamps && item.signatureTimestamps.length) {
					for (var j = 0; j < item.signatureTimestamps.length; j += 1) {
						str += _this.constructTimestampDetails(item.signatureTimestamps[j], "signature", true) + "\n"
					}
				}

				if (item.signerInfo.extensionsCertificateInfo.value.isElectronicStamp.value.length > 0) {
					var isElectronicStampTitle = item.signerInfo.extensionsCertificateInfo.value.isElectronicStamp.value === "true" ? i18next.t("yesUCase") : i18next.t("noUCase")
					str += i18next.t("ds:isElectronicStampTitle") + isElectronicStampTitle + "\n"
				}

				if (item.signerInfo.extensionsCertificateInfo.value.isQualified.value.length > 0) {
					var qualifiedCertTitle = item.signerInfo.extensionsCertificateInfo.value.isQualified.value === "true" ? i18next.t("yesUCase") : i18next.t("noUCase")
					str += i18next.t("ds:qualifiedCertTitle") + qualifiedCertTitle + "\n"
				}

				if (item.signerInfo.extensionsCertificateInfo.value.isPrivateKeyOnQSCD.value.length > 0) {
					var QSCDTitle = item.signerInfo.extensionsCertificateInfo.value.isPrivateKeyOnQSCD.value === "true" ? i18next.t("yesUCase") : i18next.t("noUCase")
					str += i18next.t("ds:QSCDTitle") + QSCDTitle + "\n"
				}

				if (item.signerInfo.extensionsCertificateInfo.value.qscdTypeName) {
					var QSCDTypeTitle = item.signerInfo.extensionsCertificateInfo.value.qscdTypeName.value === null ? i18next.t("ds:notDefined") : item.signerInfo.extensionsCertificateInfo.value.qscdTypeName.value
					str += i18next.t("ds:QSCDTypeTitle") + QSCDTypeTitle + "\n"
				}

				tempData = await _this.getQR(str);
				
    			qrFiles.push(tempData)
    			_this.setState(function(){
				    return {"qrForFiles": qrFiles}
				});

				str = "";
			}
		}

		processArray(verifyResults)	
	}

	async verifyFiles(file, index) {
		var _this = this;
		if (!_this.props.createDSDefaultState.active) {
			return
		}

		if (!_this.props.defaultState.generateQR) {
			return
		}

		if (this.props.defaultState.signatureType !== "attached") {
			await this.props.actions.sendInputData(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.createDSDefaultState.fileForDS)
		}
    	
    	await this.props.actions.sendDSData(this.props.connectionStatus.baseUrl, this.state.uuid, file)
		await this.props.actions.sendSessionOption(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.defaultState)
		await this.props.actions.verifyDSData(this.props.connectionStatus.baseUrl, this.state.uuid)
		await this.props.actions.getVerifiedData(this.props.connectionStatus.baseUrl, this.state.uuid)
		.then(async (response) => {
			await this.createQRData(response.verifyResults, index)
			this.props.actions.setVerifyResultsForAddToExist(response.verifyResults)
		})
		.then(() => {
			console.log(_this.state.qrForFiles);
		})
	}

	formatDate(dateInString) {
        var localeDateOptions = {
            "day": "numeric",
            "month": "numeric",
            "year": "numeric",
            "hour": "2-digit",
            "minute": "2-digit",
            "second": "2-digit",
            "timeZoneName": "short"
        }

        return new Date(dateInString).toLocaleDateString(i18next.language, localeDateOptions);
    }

    constructTimestampDetails(aTs, aTsType, forQR) {
        let contentTs = i18next.t("dataTs");
        let signatureTs = i18next.t("dsTs");
        let tsaDS = i18next.t("tsaCertificateSerialNumberDS");
        let tsa = i18next.t("tsaCertificateSerialNumber");
        let result = [];

        let tsTypeString = function () {
            if (aTsType === "content") {
                return contentTs;
            }
            if (aTsType === "signature") {
                return signatureTs;
            }
            return "";
        }

        let tsaTsTypeString = function () {
            if (aTsType === "content") {
                return tsa;
            }
            if (aTsType === "signature") {
                return tsaDS;
            }
            return "";
        }
        
        if (forQR) {
        	var str = ""
        	if (aTs && aTs.dateTime) {
	            if (aTs.isFailure) {
	                result.push(tsTypeString() + i18next.t("ds:errorWhileVerifying"));
	            } else {
	            	str += aTs.tsaCertificateSerialNumber ? tsaTsTypeString() + aTs.tsaCertificateSerialNumber + "\n" : "";

		            str += tsTypeString() + ": " + (aTs.isValid ? i18next.t("ds:sheIsValid") : i18next.t("ds:sheIsInvalid")) + "; " + this.formatDate(aTs.dateTime)

	            	result.push(str);
	            }
	        }

	        return result
        } else {
        	if (aTs && aTs.dateTime) {
	            if (aTs.isFailure) {
	                result.push(<div>{tsTypeString() + i18next.t("ds:errorWhileVerifying")}</div>);
	            } else {
	            	result.push(
	            		aTs.tsaCertificateSerialNumber
	            		? <div><span className="font-weight-bold">{tsTypeString()}</span>
		            			{
				            		": "
				                    + (aTs.isValid ? i18next.t("ds:sheIsValid") : i18next.t("ds:sheIsInvalid"))
				                    + "; "
				                    + this.formatDate(aTs.dateTime)
			                	}
		                	<div><span className="font-weight-bold">{tsaTsTypeString()}</span>
			                	{
				            		aTs.tsaCertificateSerialNumber
			                	}
			                </div>
		                </div>
		                : <div><span className="font-weight-bold">{tsTypeString()}</span>
	            			{
			            		": "
			                    + (aTs.isValid ? i18next.t("ds:sheIsValid") : i18next.t("ds:sheIsInvalid"))
			                    + "; "
			                    + this.formatDate(aTs.dateTime)
		                	}
		                </div>
	                );
	            }
	        }
	        return result;
        }
    }

    showDsVerifyResults(verifyResults, elementToDisplay, resultType) {
		let divPtblg = [];

        for (var i = 0; i < verifyResults.length; i += 1) {
        	let signerNumber = [];
			let signerStatus = [];
			let signerDetails = [];
			let tsDetails = [];

			if (verifyResults[i].signatureTimestamps && verifyResults[i].signatureTimestamps.length) {
				for (var j = 0; j < verifyResults[i].signatureTimestamps.length; j += 1) {
					tsDetails.push(this.constructTimestampDetails(verifyResults[i].signatureTimestamps[j], "signature"))
				}
			}

			

        	signerNumber.push(<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    			{i18next.t("ds:signature") + (i + 1)}
        	</div>)

        	if (verifyResults[i].status === "SUCCESS") {
        		if (resultType === "verifyResults") {
        			signerStatus.push(<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
	        			{
	        				verifyResults[i].isValid ? i18next.t("ds:heIsValid") : i18next.t("ds:heIsInvalid")
	        			}
	        			<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-left-right-padding">
		        			{
		        				(verifyResults[i].signerInfo.extensionsCertificateInfo.value.isPrivateKeyOnQSCD.value === "true" && verifyResults[i].signerInfo.extensionsCertificateInfo.value.isQualified.value === "true") ? i18next.t("ds:qualifiedTitle") : i18next.t("ds:unqualifiedTitle")
		        			}
		        		</div>
	        		</div>)
        		} else {
        			signerStatus.push(<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
	        			{
	        				verifyResults[i].isValid ? i18next.t("ds:heIsValid") : i18next.t("ds:heIsInvalid")
	        			}
	        			<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-left-right-padding">
		        			{
		        				(verifyResults[i].signerInfo.extensionsCertificateInfo.value.isPrivateKeyOnQSCD.value === "true" && verifyResults[i].signerInfo.extensionsCertificateInfo.value.isQualified.value === "true") ? i18next.t("ds:qualifiedTitle") : i18next.t("ds:unqualifiedTitle")
		        			}
		        		</div>
	        		</div>)
        		}
		        
		        signerDetails.push(<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 signer-details">
	    			<div>
	    				<span className="font-weight-bold">{ i18next.t("ds:signer")}</span> <span>{verifyResults[i].signerInfo.ownerCertificateInfo.value.ownerFullName.value }</span>
	    			</div>
	    				{
							verifyResults[i].signerInfo.ownerCertificateInfo.value.ownerOrganization.value ?
		                        <div>
				    				<span className="font-weight-bold">{ i18next.t("ds:organizationName") }</span> <span>{verifyResults[i].signerInfo.ownerCertificateInfo.value.ownerOrganization.value }</span>
				        		</div> : null
				        } {
							verifyResults[i].signerInfo.ownerCertificateInfo.value.ownerPosition.value ?
		                        <div>
				    				<span className="font-weight-bold">{ i18next.t("ds:title") }</span> <span>{verifyResults[i].signerInfo.ownerCertificateInfo.value.ownerPosition.value }</span>
				        		</div> : null
				        } {
							verifyResults[i].signerInfo.extensionsCertificateInfo.value.personalData.value.drfou.value ?
		                        <div>
				    				<span className="font-weight-bold">{ i18next.t("ds:drfou") }</span> <span>{verifyResults[i].signerInfo.extensionsCertificateInfo.value.personalData.value.drfou.value }</span>
				        		</div> : null
				        } {
				        	verifyResults[i].signerInfo.extensionsCertificateInfo.value.personalData.value.edrpou.value ? 
					        	<div>
				    				<span className="font-weight-bold">{ i18next.t("ds:edrpou")}</span><span>{ verifyResults[i].signerInfo.extensionsCertificateInfo.value.personalData.value.edrpou.value }</span>
				        		</div> : null
				        } {
				        	verifyResults[i].signerInfo.extensionsCertificateInfo.value.personalData.value.eddrun.value ? 
				        		<div>
				    				<span className="font-weight-bold">{ i18next.t("ds:eddrun")}</span><span>{ verifyResults[i].signerInfo.extensionsCertificateInfo.value.personalData.value.eddrun.value }</span>
				        		</div> : null
				        } {
				        	verifyResults[i].signerInfo.certificateSerialNumber.value ? 
		                        <div>
				    				<span className="font-weight-bold">{ i18next.t("certificateSerialNumberTitle")}</span><span>{verifyResults[i].signerInfo.certificateSerialNumber.value.replace(/\s/g, "") }</span>
				        		</div> : null
	                    }
	    			<div>
	    				<span className="font-weight-bold">{ i18next.t("ds:ca")}</span><span>{verifyResults[i].signerInfo.issuerCertificateInfo.value.issuerFullName.value }</span>
	        		</div>
	    			<div>
	    				{
	    					verifyResults[i].signingDateTime !== undefined && verifyResults[i].signingDateTime.length > 0
	    					? <span><span className="font-weight-bold">{ i18next.t("ds:dsDate")}</span><span>{this.formatDate(verifyResults[i].signingDateTime)}</span></span>
	    					: null
	    				}
	    			</div>
	    			<div>
	    				{
	    					this.constructTimestampDetails(verifyResults[i].contentTimestamp, "content")
	    				}
	    			</div>
	    			<div>
	    				{
	    					tsDetails
		                }
	    			</div>
	    			<div>
	    				{
							verifyResults[i].signerInfo.extensionsCertificateInfo.value.isElectronicStamp ?
		                        <div>
				    				<span className="font-weight-bold">{ i18next.t("ds:isElectronicStampTitle") }</span> <span>{verifyResults[i].signerInfo.extensionsCertificateInfo.value.isElectronicStamp.value === "true" ? i18next.t("yesUCase") : i18next.t("noUCase")}</span>
				        		</div> : null
				        }
	    				{
							verifyResults[i].signerInfo.extensionsCertificateInfo.value.isQualified ?
		                        <div>
				    				<span className="font-weight-bold">{ i18next.t("ds:qualifiedCertTitle") }</span> <span>{verifyResults[i].signerInfo.extensionsCertificateInfo.value.isQualified.value === "true" ? i18next.t("yesUCase") : i18next.t("noUCase")}</span>
				        		</div> : null
				        }
				        {
							verifyResults[i].signerInfo.extensionsCertificateInfo.value.isPrivateKeyOnQSCD ?
		                        <div>
				    				<span className="font-weight-bold">{ i18next.t("ds:QSCDTitle") }</span> <span>{verifyResults[i].signerInfo.extensionsCertificateInfo.value.isPrivateKeyOnQSCD.value === "true" ? i18next.t("yesUCase") : i18next.t("noUCase")}</span>
				        		</div> : null
				        }
				        {
							verifyResults[i].signerInfo.extensionsCertificateInfo.value.qscdTypeName ?
		                        <div>
				    				<span className="font-weight-bold">{ i18next.t("ds:QSCDTypeTitle") }</span> <span>{verifyResults[i].signerInfo.extensionsCertificateInfo.value.qscdTypeName.value === null ? i18next.t("ds:notDefined") : verifyResults[i].signerInfo.extensionsCertificateInfo.value.qscdTypeName.value}</span>
				        		</div> : null
				        }
	    			</div>
	    		</div>)

	        	divPtblg.push(<div className="ptblg-default"  style={{marginLeft: "-10px", marginRight: "-10px", marginTop: "-10px"}}>
	        		{
						 <div className={verifyResults[i].isValid ? "alert alert-success" : "alert alert-warning"}>
			        		<div className="row">
			        			<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 no-left-no-right-padding">{signerNumber} {signerStatus}<img src={this.props.createDSDefaultState.verifyResultsForQR[i]} onClick={this.handleShowQR.bind(this, i)} style={{width: "100px", height: "100px", marginLeft: "15px", cursor: "pointer"}} alt="" /> </div> <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 no-left-no-right-padding">{signerDetails}</div>
			        		</div>
			        	</div>
			        }
	        	</div>)
	        } else {
		        signerStatus.push(<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
	        			{i18next.t("ds:notDefined")}
	        		</div>)
		        signerDetails.push(<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 signer-details">
		    			<div>
		    				{ verifyResults[i].failureCause }
		    				{
					            verifyResults[i].isUntrustedValid
					            ? <div><p></p><div><b>{i18next.t("ds:signatureValidWithUntrustedTime")}</b></div></div>
					            : null
					        }
		    			</div>
	    			</div>)
	        	divPtblg.push(<div className="ptblg-default" style={{marginLeft: "-10px", marginRight: "-10px", marginTop: "-10px"}}>
	        		<div className={verifyResults[i].isValid ? "alert alert-success" : "alert alert-warning"}>
		        		<div className="row">
		        			<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 no-left-no-right-padding">{signerNumber} {signerStatus}</div> <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 no-left-no-right-padding">{signerDetails}</div>
		        		</div>
		        	</div>
	        	</div>)
            }
        }
        return divPtblg
    }

	StringEncoder(charset, javaCompliant) {
		this.charset = charset;
		this.javaCompliant = javaCompliant;
		
		if (charset !== "UTF-16LE" && 
			charset !== "UTF-8") {
			return
		}
		
		if (charset === "UTF-16LE") {
			this.encode = function(str) {
				return this.StringToUTF16LEArray(str, !javaCompliant);
			};
			this.decode = this.UTF16LEArrayToString;
		} else if (charset === "UTF-8") {
			if (javaCompliant)
				this.encode = this.StringToArray;
			else {
				this.encode = function(str) {
					var arr = this.StringToArray(str);
					arr.push(0);
				}
			}
			this.decode = this.ArrayToString;
		}
	}

	setBlobData(data) {
      this.setState({blobData:data});
    }

    clearBlobData() {
      this.setState({blobData:null});
    }

    cleanUpFileForDSInput() {
    	this.props.actions.clearInputFileForDS()
    	this.props.actions.clearDSData()
    	this.props.actions.clearVerifyResultsForAddToExist()
		this.props.actions.clearQRVerifyResultsForAddToExist()
		this.setState({"successCreation": false})
	}

	cleanUpPrevisionDsInput() {
    	this.props.actions.clearPrevisionDsFile()
    	this.props.actions.clearDSData()
    	this.props.actions.clearVerifyResultsForAddToExist()
		this.props.actions.clearQRVerifyResultsForAddToExist()
		this.setState({"successCreation": false})
	}

	cleanUpForm() {
		this.props.actions.setCleanUpForm(false)
		this.cleanUpFileForDSInput()
		this.cleanUpPrevisionDsInput()
		this.props.actions.clearInputDescription()
		this.props.actions.clearDSData()
		this.props.actions.clearVerifyResultsForAddToExist()
		this.props.actions.clearQRVerifyResultsForAddToExist()
		this.props.actions.clearQRList()
		this.setState({"qrForFiles": []})
		this.setState({"successCreation": false})
	}

	createMobileDS() {

		var profileData = {
    		number: this.props.pkLocalState.fullPhoneNumber,
    		position: this.props.pkLocalState.positionId,
    		service:  this.props.pkLocalState.serviceId
    	}

		this.setState({isLoading: true})
		this.props.actions.createSession(this.props.base.cloudURLForMobileDS)
        .then((response) => {
          this.setState({uuid: response.ticketUuid})
          console.log(response);
          return this.props.defaultState.duplicateSign && this.props.defaultState.signatureType === "attached"
          		? this.props.actions.sendDSData(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.createDSDefaultState.previsionDsFile)
	            .then(() => {
					return this.props.actions.sendSessionOption(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.defaultState)
			            .then(() => this.props.actions.createMobileDS(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.pkLocalState.msspId, profileData))
			            .then(() => this.props.actions.getMobileDSData(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.pkLocalState.msspId))
			            .then((response) => {
		            		if (response !== null) {
		            			this.props.actions.setDSData(response)
		                    	console.log(response)
		                    }
		                })
			            .then(() => this.props.actions.getCreateMobileDSResult(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.pkLocalState.msspId))
			            .then((response) => {
		            		if (response.failureCause !== undefined) {
		                    	console.log(response)
		                    	alert(i18next.t("ds:dsResponseFailed"))
		            		} else {
		            			// alert(i18next.t("ds:dsResponseSuccess"))
		            		}
		            		this.setState({isLoading: false})
		                })
			            .then(() => this.props.actions.deleteSession(this.props.base.cloudURLForMobileDS, this.state.uuid))
				}) : this.props.defaultState.duplicateSign && this.props.defaultState.signatureType === "detached"
				? this.props.actions.sendInputData(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.createDSDefaultState.fileForDS)
				.then(() => {
					return this.props.actions.sendDSData(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.createDSDefaultState.previsionDsFile)
						.then(() => this.props.actions.sendSessionMetaData(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.createDSDefaultState.fileForDSDescription)
			            .then(() => this.props.actions.sendSessionOption(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.defaultState))
			            .then(() => this.props.actions.createMobileDS(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.pkLocalState.msspId, profileData))
			            .then(() => this.props.actions.getMobileDSData(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.pkLocalState.msspId))
			            .then((response) => {
		            		if (response !== null) {
		            			this.props.actions.setDSData(response)
		                    	console.log(response)
		                    }
		                })
			            .then(() => this.props.actions.getCreateMobileDSResult(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.pkLocalState.msspId))
			            .then((response) => {
		            		if (response.failureCause !== undefined) {
		                    	console.log(response)
		                    	alert(i18next.t("ds:dsResponseFailed"))
		            		} else {
		            			// alert(i18next.t("ds:dsResponseSuccess"))
		            		}
		            		this.setState({isLoading: false})
		                })
		            	.then(() => this.props.actions.deleteSession(this.props.base.cloudURLForMobileDS, this.state.uuid)))
				}) : this.props.actions.sendInputData(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.createDSDefaultState.fileForDS)
				.then(() => {
					return this.props.actions.sendSessionMetaData(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.createDSDefaultState.fileForDSDescription)
						.then(() => this.props.actions.sendSessionOption(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.defaultState))
			            .then(() => this.props.actions.createMobileDS(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.pkLocalState.msspId, profileData))
			            .then(() => this.props.actions.getMobileDSData(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.pkLocalState.msspId))
			            .then((response) => {
		            		if (response !== null) {
		            			this.props.actions.setDSData(response)
		                    	console.log(response)
		                    }
		                })
			            .then(() => this.props.actions.getCreateMobileDSResult(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.pkLocalState.msspId))
			            .then((response) => {
		            		if (response.failureCause !== undefined) {
		                    	console.log(response)
		                    	alert(i18next.t("ds:dsResponseFailed"))
		            		} else {
		            			// alert(i18next.t("ds:dsResponseSuccess"))
		            		}
		            		this.setState({isLoading: false})
		                })
		            .then(() => this.props.actions.deleteSession(this.props.base.cloudURLForMobileDS, this.state.uuid))
				})
        })
	}

	withToken(settedToken) {
		var url, errorMessage;
		this.setState({"successCreation": false})

		if (this.props.pkLocalState.useUccConnection) {
			url = this.props.base.urlUCC + "/api/v1"
		} else {
			url = this.props.base.urlCloud + "/api/v1"
		}

		var keyContainerPassword
	    if (this.props.pkLocalState.depositsignSelectedKey !== null) {
	        const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCDAChZGnfpj1SsGLIPCaHaL7ybqpMgvphowUroGp7WZ3XA7O3ucaHfIxQFOMNAKUooIkebpp+tLO6imROzxVTmZfySpNNdfBx3E3BU9VzXCBBEKyCrmNxH3AWwuQeoi1Z1ZZLFDI02AjQYR8Nd7TutDGS7FuYlMmIKHYW2+NGHSQIDAQAB";

	        const jsEncrypt = new JSEncrypt();
	        jsEncrypt.setPublicKey(publicKey);

	        if (this.props.pkLocalState.useTwoFactorCode) {
	            keyContainerPassword = aes.encrypt(this.props.pkLocalState.tmpPin, this.props.pkLocalState.tmpTwoFactorCode).toString();
	        } else { 
	            keyContainerPassword = jsEncrypt.encrypt(this.props.pkLocalState.tmpPin);
	        }
	    } else {
	        keyContainerPassword = this.props.pkLocalState.keyContainerPassword
	    }

		this.setState({isLoading: true})
		this.props.actions.createSession(url)
        .then((response) => {
          this.setState({uuid: response.ticketUuid})
          console.log(response);
          return this.props.defaultState.duplicateSign && this.props.defaultState.signatureType === "attached"
          		? this.props.actions.sendDSData(url, this.state.uuid, this.props.createDSDefaultState.previsionDsFile)
	            .then(() => {
					return this.props.actions.sendSessionOption(url, this.state.uuid, this.props.defaultState)
			            .then(() => this.props.actions.sendTokenPath(url, this.state.uuid, settedToken, this.props.pkLocalState.depositsignKeysInfo !== null ? this.props.pkLocalState.depositsignKeysInfo.Token : null))
			            .then(() => this.props.actions.createDS(url, this.state.uuid, keyContainerPassword))
			            .then(() => this.props.actions.getDSResult(url, this.state.uuid))
			            .then((response) => {
			            		if (response !== null) {
			            			this.props.actions.setDSData(response)
			                    	console.log(response)
			                    	this.setState({"successCreation": true})
			                    	// alert(i18next.t("ds:dsResponseSuccess"))
			            		} else {
			            			this.props.actions.getDSStatus(url, this.state.uuid)
			            			.then((response) => {
			            				// alert(i18next.t("ds:dsResponseFailed") + (this.props.pkLocalState.depositsignKeysInfo !== null ? ( "\n" + response.message + (response.failureCause !== undefined ? ("\n" + response.failureCause) : "")) : ""))
			            				errorMessage = {
				                          message: response.message,
				                          techMessage: response.techMessage || response.failureCause
				                        }
				                        this.props.actions.setErrorData(errorMessage)
				                        this.props.actions.setShowErrorPopup(true)		            				
			            			})
			            			// alert(i18next.t("ds:dsResponseFailed"))
			            		}
			            		this.setState({isLoading: false})
			            		return this.verifyFiles(response, 0)
			            		
			                  })
		            .then(() => this.props.actions.deleteSession(url, this.state.uuid))
		            .then(() => this.props.actions.setTmpPin(""))
					.then(() => this.props.actions.setTmpTwoFactorCode(""))
		            .then(() => this.props.actions.setQRVerifyResultsForAddToExist(this.state.qrForFiles))
				}) : this.props.defaultState.duplicateSign && this.props.defaultState.signatureType === "detached"
				? this.props.actions.sendInputData(url, this.state.uuid, this.props.createDSDefaultState.fileForDS)
				.then(() => {
					return this.props.actions.sendDSData(url, this.state.uuid, this.props.createDSDefaultState.previsionDsFile)
					.then(() => this.props.actions.sendSessionMetaData(url, this.state.uuid, this.props.createDSDefaultState.fileForDSDescription)
		            .then(() => this.props.actions.sendSessionOption(url, this.state.uuid, this.props.defaultState))
		            .then(() => this.props.actions.sendTokenPath(url, this.state.uuid, settedToken, this.props.pkLocalState.depositsignKeysInfo !== null ? this.props.pkLocalState.depositsignKeysInfo.Token : null))
		            .then(() => this.props.actions.createDS(url, this.state.uuid, keyContainerPassword))
		            .then(() => this.props.actions.getDSResult(url, this.state.uuid))
		            .then((response) => {
		            		if (response !== null) {
		            			this.props.actions.setDSData(response)
		                    	console.log(response)
		                    	this.setState({"successCreation": true})
		                    	// alert(i18next.t("ds:dsResponseSuccess"))
		            		} else {
		            			this.props.actions.getDSStatus(url, this.state.uuid)
		            			.then((response) => {
		            				errorMessage = {
			                          message: response.message,
			                          techMessage: response.techMessage || response.failureCause
			                        }
			                        this.props.actions.setErrorData(errorMessage)
			                        this.props.actions.setShowErrorPopup(true)
		            				// alert(i18next.t("ds:dsResponseFailed") + (this.props.pkLocalState.depositsignKeysInfo !== null ? ( "\n" + response.message + (response.failureCause !== undefined ? ("\n" + response.failureCause) : "")) : ""))
		            			})
		            		}
		            		this.setState({isLoading: false})
		            		return this.verifyFiles(response, 0)
		                  })
		            .then(() => this.props.actions.deleteSession(url, this.state.uuid))
		            .then(() => this.props.actions.setTmpPin(""))
					.then(() => this.props.actions.setTmpTwoFactorCode(""))
		            .then(() => this.props.actions.setQRVerifyResultsForAddToExist(this.state.qrForFiles)))
				}) : this.props.actions.sendInputData(url, this.state.uuid, this.props.createDSDefaultState.fileForDS)
				.then(() => {
					return this.props.actions.sendSessionMetaData(url, this.state.uuid, this.props.createDSDefaultState.fileForDSDescription)
					.then(() => this.props.actions.sendSessionOption(url, this.state.uuid, this.props.defaultState))
		            .then(() => this.props.actions.sendTokenPath(url, this.state.uuid, settedToken, this.props.pkLocalState.depositsignKeysInfo !== null ? this.props.pkLocalState.depositsignKeysInfo.Token : null))
		            .then(() => this.props.actions.createDS(url, this.state.uuid, keyContainerPassword))
		            .then(() => this.props.actions.getDSResult(url, this.state.uuid))
		            .then((response) => {
		            		if (response !== null) {
		            			this.props.actions.setDSData(response)
		                    	console.log(response)
		                    	this.setState({"successCreation": true})
		                    	// alert(i18next.t("ds:dsResponseSuccess"))
		            		} else {
		            			this.props.actions.getDSStatus(url, this.state.uuid)
		            			.then((response) => {
		            				errorMessage = {
			                          message: response.message,
			                          techMessage: response.techMessage || response.failureCause
			                        }
			                        this.props.actions.setErrorData(errorMessage)
			                        this.props.actions.setShowErrorPopup(true)
		            				// alert(i18next.t("ds:dsResponseFailed") + (this.props.pkLocalState.depositsignKeysInfo !== null ? ( "\n" + response.message + (response.failureCause !== undefined ? ("\n" + response.failureCause) : "")) : ""))
		            			})
		            		}
		            		this.setState({isLoading: false})
		                  	return this.verifyFiles(response, 0)
				        })
		            .then(() => this.props.actions.deleteSession(url, this.state.uuid))
		            .then(() => this.props.actions.setTmpPin(""))
					.then(() => this.props.actions.setTmpTwoFactorCode(""))
		            .then(() => this.props.actions.setQRVerifyResultsForAddToExist(this.state.qrForFiles))
				})
        })
	}

	selectVtcoSign () {
		if (this.props.pkLocalState.isTwoFactorEnable) {
			// this.createVtcoSign2f()
			this.setState({numberOfPinInput: this.props.base.numberOfPinInput});
			this.setState({needToReEnterPin: true});
			this.setState({"showVtcoConfirmPopup": true})
		} else {
			this.createVtcoSign()
		}
	}

	closeVtcoConfirm (value) {
		if (value === true) {
			this.setState({"showVtcoConfirmPopup": false})
			this.setState({isLoading: false})
			return;
		} else {
			if (this.state.showVtcoConfirmPopup === true && this.state.needToReEnterPin === true) {
				if (this.state.numberOfPinInput > 0) {
					this.createVtcoSign2f()
				} else {
					this.setState({needToReEnterPin: false});
					this.setState({numberOfPinInput: 0});
					this.setState({isLoading: false});
				}
			}

			this.setState({"showVtcoConfirmPopup": false})
		}
	}

	createVtcoSign2f () {
		var data, _this = this, errorMessage;
		this.setState({isLoading: true})
		
		data = {
		    "ciHsmTokenUri": _this.props.pkLocalState.vtcoSelectedKey.uri,
		    "operationType": "SIGNING",
		    "twoFactorCode" : _this.props.pkLocalState.vtcoTwoFactorCode
		}

		_this.props.actions.premissionVtco(this.props.base.vtcoURL, data, this.props.pkLocalState.vtcoTokenBody)
		.then((response) => {
	    	if (response.code !== undefined) {
	    		if (response.code === "AUTHCOMMON-14") {
            		var currentCount = _this.state.numberOfPinInput

            		currentCount = currentCount - 1;

 					if (currentCount > 0) {
 						_this.setState({numberOfPinInput: currentCount});
						_this.setState({needToReEnterPin: true});
						_this.setState({showVtcoConfirmPopup: true})
 					} else {
 						_this.setState({numberOfPinInput: 0});
 						_this.setState({needToReEnterPin: false});
						_this.setState({showVtcoConfirmPopup: false})
						_this.setState({isLoading: false})
 					}
            	} else {
            		_this.setState({isLoading: false})
            		errorMessage = {
                      message: response.message,
                      techMessage: response.techMessage
                    }
                    _this.props.actions.setErrorData(errorMessage)
                    _this.props.actions.setShowErrorPopup(true)
		    		// var msg = response.message + "\n" + response.techMessage
		    		// alert(msg);
            	}
	    	} else {
	    		_this.createVtcoSign();
	    	}
	    })
	}

	createVtcoSign () {
		var resultDataArray = [], inputData = [], data, _this = this, sendData, errorMessage;
		this.setState({isLoading: true})
		this.setState({"successCreation": false})

		sendData = function () {

			if (_this.props.defaultState.signatureType === "detached") {

				data = {
					"detachedSignature" : resultDataArray,
					"signedData" : inputData
				}

				_this.props.actions.convertToAttached(_this.props.base.vtcoURL, data, _this.props.pkLocalState.vtcoTokenBody)
				.then((response) => {

					if (response.code !== undefined) {
			    		_this.setState({isLoading: false})
			    		errorMessage = {
                          message: response.message,
                          techMessage: response.techMessage
                        }
                        _this.props.actions.setErrorData(errorMessage)
                        _this.props.actions.setShowErrorPopup(true)
			    		// var msg = response.message + "\n" + response.techMessage
			    		// alert(msg);
			    		return;
			    	}

					var jsonObject = {
			        	pin : _this.props.pkLocalState.vtcoPin,
						embedContentTs : true,
						signatureType : _this.props.defaultState["signatureType"].toUpperCase(),
						signatureFormat : _this.props.defaultState["cadesType"] === "CAdESBES" ? "CADES_BES" : "CADES_X_LONG",
						embedSignerCertificate : true,
						asicForm : "NONE",
						dataToSignQualifier: "ALREADY_SIGNED",
						dataToSign : [response.attachedSignature]
				    };

				    var cipherResult = cipher(jsonObject, _this.props.pkLocalState.publicKeyTextual, _this.props.pkLocalState.currentUtcDateTime);

				    data = {
				        "ciHsmTokenUri": _this.props.pkLocalState.vtcoSelectedKey.uri,
				        "encryptedData": cipherResult.encryptedData,
				        "secretKey": cipherResult.secretKey
				    }

				    _this.props.actions.createVtcoDs(_this.props.base.vtcoURL, data, _this.props.pkLocalState.vtcoTokenBody)
				    .then((response) => {
				    	if (response.code !== undefined) {
				    		_this.setState({isLoading: false})
				    		errorMessage = {
	                          message: response.message,
	                          techMessage: response.techMessage
	                        }
	                        _this.props.actions.setErrorData(errorMessage)
	                        _this.props.actions.setShowErrorPopup(true)
				    		// var msg = response.message + "\n" + response.techMessage
				    		// alert(msg);
				    	} else {
				    		data = {
								"attachedSignature" : response[0]
							}

							_this.props.actions.convertToDetached(_this.props.base.vtcoURL, data, _this.props.pkLocalState.vtcoTokenBody)
							.then((response) => {
								console.log(response)
								if (response.code !== undefined) {
						    		_this.setState({isLoading: false})
						    		errorMessage = {
			                          message: response.message,
			                          techMessage: response.techMessage
			                        }
			                        _this.props.actions.setErrorData(errorMessage)
			                        _this.props.actions.setShowErrorPopup(true)
						    		// var msg = response.message + "\n" + response.techMessage
						    		// alert(msg);
						    	} else {
						    		_this.setState({"filesWithDS": response.signedData});
							    	_this.props.actions.setDSData(response.detachedSignature)
							    	_this.setState({isLoading: false})
							    	_this.setState({"successCreation": true})
						    	}
							})
				    	}
				    })
				})
			} else {
				var jsonObject = {
		        	pin : _this.props.pkLocalState.vtcoPin,
					embedContentTs : true,
					signatureType : _this.props.defaultState["signatureType"].toUpperCase(),
					signatureFormat : _this.props.defaultState["cadesType"] === "CAdESBES" ? "CADES_BES" : "CADES_X_LONG",
					embedSignerCertificate : true,
					asicForm : "NONE",
					dataToSignQualifier: "ALREADY_SIGNED",
					dataToSign : [resultDataArray]
			    };

			    var cipherResult = cipher(jsonObject, _this.props.pkLocalState.publicKeyTextual, _this.props.pkLocalState.currentUtcDateTime);

			    data = {
			        "ciHsmTokenUri": _this.props.pkLocalState.vtcoSelectedKey.uri,
			        "encryptedData": cipherResult.encryptedData,
			        "secretKey": cipherResult.secretKey
			    }

			    _this.props.actions.createVtcoDs(_this.props.base.vtcoURL, data, _this.props.pkLocalState.vtcoTokenBody)
			    .then((response) => {
			    	if (response.code !== undefined) {
			    		_this.setState({isLoading: false})
			    		errorMessage = {
                          message: response.message,
                          techMessage: response.techMessage
                        }
                        _this.props.actions.setErrorData(errorMessage)
                        _this.props.actions.setShowErrorPopup(true)
			    		// var msg = response.message + "\n" + response.techMessage
			    		// alert(msg);
			    	} else {
			    		_this.setState({"filesWithDS": response});
				    	_this.props.actions.setDSData(response)
				    	_this.setState({isLoading: false})
				    	_this.setState({"successCreation": true})
			    	}
			    })
			}
		}

		function readFile(file) {
		  return new Promise((resolve, reject) => {
		    const reader = new FileReader();

		    reader.onload = res => {
		    var base64String = window.btoa(res.target.result);
		      resolve(base64String);
		    };
		    reader.onerror = err => reject(err);

		    reader.readAsBinaryString(file);
		  });
		}

    	async function read(file, data) {
			resultDataArray = await readFile(file);

			if (_this.props.defaultState.signatureType === "detached") {
				inputData = await readFile(data);

				sendData()
			} else {
				sendData()
			}
		}

        read(this.props.createDSDefaultState.previsionDsFile, this.props.createDSDefaultState.fileForDS);
	}

	createDS() {
		let settedToken, url, _this = this, errorMessage;
		this.setState({"successCreation": false})

		if (this.props.base.isVtco) {
			if (this.props.pkLocalState.isTwoFactorEnable) {
				var data = {
				    "ciHsmTokenUri": this.props.pkLocalState.vtcoSelectedKey.uri,
				    "operationType": "SIGNING",
				    "permissionTtlSeconds" : 10,
				    "permissionRepetition" : 2
				}

				this.props.actions.premission2fVtco(this.props.base.vtcoURL, data, this.props.pkLocalState.vtcoTokenBody)
			    .then((response) => {
			    	if (response.code !== undefined) {
			    		_this.setState({isLoading: false})
			    		errorMessage = {
                          message: response.message,
                          techMessage: response.techMessage
                        }
                        _this.props.actions.setErrorData(errorMessage)
                        _this.props.actions.setShowErrorPopup(true)
			    		// var msg = response.message + "\n" + response.techMessage
			    		// alert(msg);
			    	} else {
			    		this.selectVtcoSign();
			    	}
			    })
			} else {
				this.selectVtcoSign();
			}

			return;
		}

		if (this.props.pkLocalState.useUccConnection) {
			url = this.props.base.urlUCC + "/api/v1"
		} else {
			url = this.props.base.urlCloud + "/api/v1"
		}

		if (this.props.pkLocalState.tokenPathHSM.length > 0) {
			settedToken = {
				keyStoreUri: this.props.pkLocalState.tokenPathHSM
			}
		} else {
			if (this.props.pkLocalState.depositsignSelectedKey !== null) {
				settedToken = {
					keyStoreUri: this.props.pkLocalState.depositsignSelectedKey.KeyName
				}
			} else {
				settedToken = {
					keyStorePath: this.props.pkLocalState.activeToken ? this.props.pkLocalState.activeToken : this.props.pkLocalState.passiveToken
				}
			}
		}

		var keyContainerPassword
	    if (this.props.pkLocalState.depositsignSelectedKey !== null) {
	        const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCDAChZGnfpj1SsGLIPCaHaL7ybqpMgvphowUroGp7WZ3XA7O3ucaHfIxQFOMNAKUooIkebpp+tLO6imROzxVTmZfySpNNdfBx3E3BU9VzXCBBEKyCrmNxH3AWwuQeoi1Z1ZZLFDI02AjQYR8Nd7TutDGS7FuYlMmIKHYW2+NGHSQIDAQAB";

	        const jsEncrypt = new JSEncrypt();
	        jsEncrypt.setPublicKey(publicKey);

	        if (this.props.pkLocalState.useTwoFactorCode) {
	            keyContainerPassword = aes.encrypt(this.props.pkLocalState.tmpPin, this.props.pkLocalState.tmpTwoFactorCode).toString();
	        } else { 
	            keyContainerPassword = jsEncrypt.encrypt(this.props.pkLocalState.tmpPin);
	        }
	    } else {
	        keyContainerPassword = this.props.pkLocalState.keyContainerPassword
	    }

		if (this.props.pkLocalState.mobileDSMode) {
			this.createMobileDS();
			return;
		}

		if (this.props.pkLocalState.keyContainer !== null) {
			if (this.props.connectionStatus.uccConnection && typeof this.props.pkLocalState.keyContainer === 'string') {
				let keyPath
				if (this.props.pkLocalState.tokenPathHSM.length > 0) {
					keyPath = {"keyStoreUri": this.props.pkLocalState.keyContainer}
				} else {
					keyPath = {"keyStorePath": this.props.pkLocalState.keyContainer}
				}
				
	            this.setState({"settedToken": keyPath})

	            if (settedToken.keyStorePath.length > 0) {
	            	this.withToken(settedToken)
	            } else {
	            	this.withToken(keyPath)
	            }
	            
	        } else {
	        	this.setState({isLoading: true})
				this.props.actions.createSession(url)
		        .then((response) => {
		          this.setState({uuid: response.ticketUuid})
		          console.log(response);
		          return this.props.defaultState.duplicateSign && this.props.defaultState.signatureType === "attached"
		          		? this.props.actions.sendDSData(url, this.state.uuid, this.props.createDSDefaultState.previsionDsFile)
			            .then(() => {
							return this.props.actions.sendSessionOption(url, this.state.uuid, this.props.defaultState)
					            .then(() => this.props.actions.sendKeyContainer(url, this.state.uuid, this.props.pkLocalState.keyContainer))
					            .then(() => this.props.actions.createDS(url, this.state.uuid, keyContainerPassword))
					            .then(() => this.props.actions.getDSResult(url, this.state.uuid))
					            .then((response) => {
				            		if (response !== null) {
				            			this.props.actions.setDSData(response)
				                    	console.log(response)
				                    	this.setState({"successCreation": true})
				                    	// alert(i18next.t("ds:dsResponseSuccess"))
				            		} else {
				            			this.props.actions.getDSStatus(url, this.state.uuid)
				            			.then((response) => {
				            				errorMessage = {
					                          message: response.message,
					                          techMessage: response.techMessage || response.failureCause
					                        }
					                        this.props.actions.setErrorData(errorMessage)
					                        this.props.actions.setShowErrorPopup(true)
				            				// alert(i18next.t("ds:dsResponseFailed") + (this.props.pkLocalState.depositsignKeysInfo !== null ? ( "\n" + response.message + (response.failureCause !== undefined ? ("\n" + response.failureCause) : "")) : ""))
				            			})
				            			// errorMessage = {
				               //            message: i18next.t("ds:dsResponseFailed")
				               //          }
				               //          this.props.actions.setErrorData(errorMessage)
				               //          this.props.actions.setShowErrorPopup(true)
				            			// alert(i18next.t("ds:dsResponseFailed"))
				            		}
				            		this.setState({isLoading: false})

				            		return this.verifyFiles(response, 0)
				                })
					            .then(() => this.props.actions.deleteSession(url, this.state.uuid))
					            .then(() => this.props.actions.setTmpPin(""))
								.then(() => this.props.actions.setTmpTwoFactorCode(""))
					            .then(() => this.props.actions.setQRVerifyResultsForAddToExist(this.state.qrForFiles))
						}) : this.props.defaultState.duplicateSign && this.props.defaultState.signatureType === "detached"
						? this.props.actions.sendInputData(url, this.state.uuid, this.props.createDSDefaultState.fileForDS)
						.then(() => {
							return this.props.actions.sendDSData(url, this.state.uuid, this.props.createDSDefaultState.previsionDsFile)
							.then(() => this.props.actions.sendSessionMetaData(url, this.state.uuid, this.props.createDSDefaultState.fileForDSDescription)
				            .then(() => this.props.actions.sendSessionOption(url, this.state.uuid, this.props.defaultState))
				            .then(() => this.props.actions.sendKeyContainer(url, this.state.uuid, this.props.pkLocalState.keyContainer))
				            .then(() => this.props.actions.createDS(url, this.state.uuid, keyContainerPassword))
				            .then(() => this.props.actions.getDSResult(url, this.state.uuid))
				            .then((response) => {
			            		if (response !== null) {
			            			this.props.actions.setDSData(response)
			                    	console.log(response)
			                    	this.setState({"successCreation": true})
			                    	// alert(i18next.t("ds:dsResponseSuccess"))
			            		} else {
			            			this.props.actions.getDSStatus(url, this.state.uuid)
			            			.then((response) => {
			            				errorMessage = {
				                          message: response.message,
				                          techMessage: response.techMessage || response.failureCause
				                        }
				                        this.props.actions.setErrorData(errorMessage)
				                        this.props.actions.setShowErrorPopup(true)
			            				// alert(i18next.t("ds:dsResponseFailed") + (this.props.pkLocalState.depositsignKeysInfo !== null ? ( "\n" + response.message + (response.failureCause !== undefined ? ("\n" + response.failureCause) : "")) : ""))
			            			})
			            			// errorMessage = {
			               //            message: i18next.t("ds:dsResponseFailed")
			               //          }
			               //          this.props.actions.setErrorData(errorMessage)
			               //          this.props.actions.setShowErrorPopup(true)
			            			// alert(i18next.t("ds:dsResponseFailed"))
			            		}
			            		this.setState({isLoading: false})

			            		return this.verifyFiles(response, 0)
			                })
				            .then(() => this.props.actions.deleteSession(url, this.state.uuid)))
				            .then(() => this.props.actions.setTmpPin(""))
							.then(() => this.props.actions.setTmpTwoFactorCode(""))
				            .then(() => this.props.actions.setQRVerifyResultsForAddToExist(this.state.qrForFiles))
						}) : this.props.actions.sendInputData(url, this.state.uuid, this.props.createDSDefaultState.fileForDS)
						.then(() => {
							return this.props.actions.sendSessionMetaData(url, this.state.uuid, this.props.createDSDefaultState.fileForDSDescription)
							.then(() => this.props.actions.sendSessionOption(url, this.state.uuid, this.props.defaultState))
				            .then(() => this.props.actions.sendKeyContainer(url, this.state.uuid, this.props.pkLocalState.keyContainer))
				            .then(() => this.props.actions.createDS(url, this.state.uuid, keyContainerPassword))
				            .then(() => this.props.actions.getDSResult(url, this.state.uuid))
				            .then((response) => {
			            		if (response !== null) {
			            			this.props.actions.setDSData(response)
			                    	console.log(response)
			                    	this.setState({"successCreation": true})
			                    	// alert(i18next.t("ds:dsResponseSuccess"))
			            		} else {
			            			this.props.actions.getDSStatus(url, this.state.uuid)
			            			.then((response) => {
			            				errorMessage = {
				                          message: response.message,
				                          techMessage: response.techMessage || response.failureCause
				                        }
				                        this.props.actions.setErrorData(errorMessage)
				                        this.props.actions.setShowErrorPopup(true)
			            				// alert(i18next.t("ds:dsResponseFailed") + (this.props.pkLocalState.depositsignKeysInfo !== null ? ( "\n" + response.message + (response.failureCause !== undefined ? ("\n" + response.failureCause) : "")) : ""))
			            			})
			            			// errorMessage = {
			               //            message: i18next.t("ds:dsResponseFailed")
			               //          }
			               //          this.props.actions.setErrorData(errorMessage)
			               //          this.props.actions.setShowErrorPopup(true)
			            			// alert(i18next.t("ds:dsResponseFailed"))
			            		}
			            		this.setState({isLoading: false})

			            		return this.verifyFiles(response, 0)
			                })
				            .then(() => this.props.actions.deleteSession(url, this.state.uuid))
				            .then(() => this.props.actions.setTmpPin(""))
							.then(() => this.props.actions.setTmpTwoFactorCode(""))
				            .then(() => this.props.actions.setQRVerifyResultsForAddToExist(this.state.qrForFiles))
						})
		        })
	        }
			
		} else {
			this.withToken(settedToken)
		}
	}

    getBlobData() {
    	var blob;
    	const b64toBlob = (b64Data, contentType, sliceSize=512) => {
		  const byteCharacters = atob(b64Data);
		  const byteArrays = [];

		  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		    const slice = byteCharacters.slice(offset, offset + sliceSize);

		    const byteNumbers = new Array(slice.length);
		    for (let i = 0; i < slice.length; i++) {
		      byteNumbers[i] = slice.charCodeAt(i);
		    }

		    const byteArray = new Uint8Array(byteNumbers);
		    byteArrays.push(byteArray);
		  }

		  const blob = new Blob(byteArrays, {type: contentType});
		  return blob;
		}

	    if (this.props.createDSDefaultState.fileForDSName.length > 0) {
	    	if (this.props.base.isVtco) {
	    		blob = b64toBlob(this.props.createDSDefaultState.dsData, {type : 'application/pkcs7-signature'});
	    		saveAs(blob, this.props.createDSDefaultState.previsionDsFileName + ".p7s")
	    	} else {
	    		saveAs(this.props.createDSDefaultState.dsData, this.props.createDSDefaultState.previsionDsFileName + ".p7s")
	    	}
	    } else {
	    	if (this.props.base.isVtco) {
	    		blob = b64toBlob(this.props.createDSDefaultState.dsData, {type : 'application/pkcs7-signature'});
	    		saveAs(blob, this.props.createDSDefaultState.previsionDsFileName + ".p7s")
	    	} else {
	    		saveAs(this.props.createDSDefaultState.dsData, this.props.createDSDefaultState.previsionDsFileName)
	    	}
	    }    
	}

	render() {
		const isEnabled = this.props.defaultState.duplicateSign && this.props.defaultState.signatureType === "attached"
			? this.props.createDSDefaultState.previsionDsFile  && !this.state.isLoading
			: this.props.defaultState.duplicateSign && !this.state.isLoading
			? this.props.createDSDefaultState.fileForDSSelected && this.props.createDSDefaultState.fileForDSDescription.length > 0 && this.props.createDSDefaultState.previsionDsFile && !this.state.isLoading
			: this.props.createDSDefaultState.fileForDSSelected && this.props.createDSDefaultState.fileForDSDescription.length > 0 && !this.state.isLoading
		const downloadEnable = this.props.createDSDefaultState.dsData && this.state.downloadIsAvailable

		return (
			<div>
		        <div className="card">
					<div className="card-header" onClick={this.expandCreateDS.bind(this)} role="button">
						{
							this.props.expandedCardsReducer.createDSAddToExistExpanded ? <i className="fa fa-chevron-down" aria-hidden="true" style={{"cursor": "pointer"}}></i>
							: <i className="fa fa-chevron-up" aria-hidden="true" style={{"cursor": "pointer"}}></i>
						}&nbsp;{i18next.t("file")}
					</div>
	              	<div className={this.state.collapseCreateDSShow}>
	                    <div>
		                    {
		                    	this.props.defaultState.duplicateSign && this.props.defaultState.signatureType === "attached" 
		                    	? null
		                    	: <div>
		                    		<div className="row">
									 	<label className="col-lg-4 col-md-12 col-xs-12 col-sm-12 col-form-label">{i18next.t("ds:fileForDsTitle")}</label>
			                            <div className="col-lg-8 col-md-12 col-xs-12 col-sm-12">
			                                {
			                                  this.props.mobileModeReducer.mobileDeviceMode
			                                  ? <div className="input-group row" style={{marginLeft: "0px"}}>
			                                    <input type="text" className="form-control col-lg-8 col-md-12 col-xs-12 col-sm-12" value={this.props.createDSDefaultState.fileForDSName} style={{"backgroundColor": "white", "cursor": "pointer"}} onClick={this.handleFileInput.bind(this)} readOnly />
			                                    {
													this.props.createDSDefaultState.fileForDSSelected
													? <button className="btn btn-default col-lg-4 col-md-4 col-xs-12 col-sm-12 margin-top-sm" style={{"cursor": "pointer"}} onClick={this.handleFileInput.bind(this)}>{i18next.t("changeFile")}</button>
													: <button className="btn btn-default col-lg-4 col-md-4 col-xs-12 col-sm-12 margin-top-sm" style={{"cursor": "pointer"}} onClick={this.handleFileInput.bind(this)}>{i18next.t("chooseFile")}</button>
												}
			                                    </div>
			                                    : <div>
			                                  		{
			                                  			this.props.createDSDefaultState.fileForDSSelected
			                                  			? <div className="input-group mb-3">
															<input type="text" className="form-control" value={this.props.createDSDefaultState.fileForDSName} style={{"backgroundColor": "white", "cursor": "pointer"}} onClick={this.handleFileInput.bind(this)} readOnly />
																{
																	<div className="input-group-append">
															      		<button className="input-group-text" style={{"cursor": "pointer"}} onClick={this.cleanUpFileForDSInput.bind(this)}>{i18next.t("cleanup")}</button>
																    </div>
																}
														</div>
									                    : <Dropzone ref={dropzoneRef} onDrop={this.handleOnDrop} multiple={false}>
									                        {({getRootProps, getInputProps}) => (
									                            <div {...getRootProps()} style={{cursor: "pointer", marginBottom: "10px"}} className="dropzone" id="dropFile">
									                                <input {...getInputProps()} />
									                                <p style={{cursor: "pointer", marginTop: "16px"}}>{i18next.t("dragNDropTitleSecond")}</p>
									                            </div>
									                        )}
									                    </Dropzone>
			                                  		}
												</div>
			                                }
			                                <input id="fileInputUpload" type="file" onChange={this.onChangeFile.bind(this)} style={{"display": "none"}} />
			                            </div>
		                            </div>
			                    	<div className="row">
										<label className="col-lg-4 col-md-12 col-xs-12 col-sm-12 col-form-label">{i18next.t("ds:metaDataFileForDsTitle")}</label>
										<div className="col-lg-8 col-md-12 col-xs-12 col-sm-12" style={{marginBottom: "10px"}}>
			                                <input id="dscMetaDataFileForDs" type="text" className="form-control col-lg-12 col-md-12 col-xs-12 col-sm-12" value={this.props.createDSDefaultState.fileForDSDescription} onChange={this.handleDescriptionChange} />
			                            </div>
			                    	</div>
			                    </div>
		                    }

							{
								this.props.defaultState.duplicateSign
									? <div className="row">
									 	<label className="col-lg-4 col-md-12 col-xs-12 col-sm-12 col-form-label">{i18next.t("ds:previsionDsFileTitle")}</label>
				                            <div className="col-lg-8 col-md-12 col-xs-12 col-sm-12" style={{marginBottom: "10px"}}>
				                                {
				                                  this.props.mobileModeReducer.mobileDeviceMode
				                                  ? 
				                                  <div className="input-group row" style={{marginLeft: "0px"}}>
				                                    <input type="text" className="form-control col-lg-8 col-md-12 col-xs-12 col-sm-12" value={this.props.createDSDefaultState.previsionDsFileName} style={{"backgroundColor": "white", "cursor": "pointer"}} onClick={this.handlePrevisionDsFile.bind(this)} readOnly />
				                                    {
														this.props.createDSDefaultState.previsionDsFileSelected
														? <button className="btn btn-default col-lg-4 col-md-4 col-xs-12 col-sm-12 margin-top-sm" style={{"cursor": "pointer"}} onClick={this.handlePrevisionDsFile.bind(this)}>{i18next.t("changeFile")}</button>
														: <button className="btn btn-default col-lg-4 col-md-4 col-xs-12 col-sm-12 margin-top-sm" style={{"cursor": "pointer"}} onClick={this.handlePrevisionDsFile.bind(this)}>{i18next.t("chooseFile")}</button>
													}
				                                    </div>
				                                    : <div>
				                                    	{
				                                    		this.props.createDSDefaultState.previsionDsFileSelected
				                                    		? <div className="input-group mb-3">
																<input type="text" className="form-control" value={this.props.createDSDefaultState.previsionDsFileName} style={{"backgroundColor": "white", "cursor": "pointer"}} onClick={this.handlePrevisionDsFile.bind(this)} readOnly />
																	<div className="input-group-append">
															      		<button className="input-group-text" style={{"cursor": "pointer"}} onClick={this.cleanUpPrevisionDsInput.bind(this)}>{i18next.t("cleanup")}</button>
																    </div>
															</div>
															: <Dropzone ref={dropzoneDSRef} onDrop={this.handleOnDropDS} multiple={false}>
										                        {({getRootProps, getInputProps}) => (
										                            <div {...getRootProps()} style={{cursor: "pointer", marginBottom: "10px"}} className="dropzone" id="dropDS">
										                                <input {...getInputProps()} />
										                                <p style={{cursor: "pointer", marginTop: "16px"}}>{i18next.t("dragNDropTitleSecond")}</p>
										                            </div>
										                        )}
										                    </Dropzone>
				                                    	}
				                                      </div>
				                                }
				                                <input id="fileInputPrevisionDsUpload" type="file" onChange={this.onChangePrevisionDsFile.bind(this)} style={{"display": "none"}} />
				                            </div>
			                            </div> : null
							}

							{
				            	this.state.successCreation
				            	? <div className="row col-12 success-action" style={{marginLeft: "0px", marginRight: "0px", marginBottom: "15px"}}>
					            	<label style={{marginBottom: "5px", marginTop: "5px"}}>{i18next.t("ds:dsSuccessfullyCreatedAddToExist")}</label>
					              </div>
					            : null
				            }

							{
								this.props.defaultState.generateQR ? this.props.createDSDefaultState.verifyResults && this.props.createDSDefaultState.verifyResultsForQR ? this.showDsVerifyResults(this.props.createDSDefaultState.verifyResults, "tmpDiv") : null
								: null
							}
							
							<div className="row">
								<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 order-1">
			                        <button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginBottom: "10px"}} onClick={this.createDSWithSigns.bind(this)} disabled={!isEnabled}>{i18next.t("ds:createDs")}
										{
			                                this.state.isLoading ? <span>&nbsp;<i className="fa fa-spinner fa-pulse"></i></span>
			                                : null
			                            }
									</button>
		                        </div>

		                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 order-0">
		                        	<button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginBottom: "10px"}} disabled={!downloadEnable} onClick={this.getBlobData.bind(this)}>{i18next.t("ds:saveDsToFile")}</button>
		                        </div>

		                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 order-2 no-left-padding">
		                        	<button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" onClick={this.cleanUpForm.bind(this)}>{i18next.t("cleanUpForm")}</button>
		                        </div>
	                        </div>
	                    </div>
	                    {
	                    	this.props.defaultState.cleanUpForm
	                    	? this.cleanUpForm()
	                    	: null
	                    }
	                </div>
	            </div>
                <CreateTextDSContainer />
                <div>
                    {
                        this.state.showPopup
                        ? <QRPopup closeQRPopup={this.handleShowQR} data={this.state.displayedData} />
                        : null
                    }
                </div>
                <div>
                    {
                        this.state.showPinPopup
                        ? <PinPopup closePinPopup={this.handleShowPin} createDSUsingPin={this.handleCreateDSUsingPin} />
                        : null
                    }
                </div>
                {
					this.state.showVtcoConfirmPopup
					? <TwoFactorConfirmPopup initAction={this.closeVtcoConfirm.bind(this)} title={"createDsConfirm"} />
					: null
				}
	        </div>
      )
	}
}


function mapStateToProps(state) {
    return {
    	language: state.localesReducer.language,
        localesReducer: state.localesReducer,
        pkLocalState: state.privateKeyReducer,
        defaultState: state.createDSOptionsReducer,
        createDSDefaultState: state.createDSInputReducer,
        createDSTextReducer: state.createDSTextReducer,
        connectionStatus: state.connectionStatusReucer,
        mobileModeReducer: state.mobileModeReducer,
        base: state.base,
        expandedCardsReducer: state.expandedCardsReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    languageSwitchItem,
		createSession,
		sendInputData,
		sendSessionMetaData,
		sendSessionOption,
		sendKeyContainer,
		createDS, getQR,
		getDSResult,
		deleteSession,
		setInputFileForDS,
		setInputDescription,
		clearInputFileForDS,
		clearInputDescription,
		setDSData, setQRList,
		clearDSData,
		sendDSData, setCleanUpForm,
		setPrevisionDsFile,
		clearPrevisionDsFile,
		sendTokenPath,
		setDSEncodingType,
		setCreateDSAddToExistExpand,
		createMobileDS, getMobileDSData,
		getCreateMobileDSResult, verifyDSData, getVerifiedData,
		setVerifyResultsForAddToExist,
		setQRVerifyResultsForAddToExist,
		clearVerifyResultsForAddToExist, clearQRVerifyResultsForAddToExist,
		clearQRList, setTmpPin, setTmpTwoFactorCode,
		createVtcoDs, premissionVtco, convertToAttached, convertToDetached,
		premission2fVtco, setErrorData, setShowErrorPopup, getDSStatus
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDSAddToExistContainer);
var forge = require('node-forge');

var lookup = []
var revLookup = []
var Arr = typeof Uint8Array !== 'undefined' ? Uint8Array : Array;

export const toByteArray = (b64) => (dispatch) => {
  var i, j, l, tmp, placeHolders, arr
  var len = b64.length

  if (len % 4 > 0) {
    throw new Error('Invalid string. Length must be a multiple of 4')
  }

  placeHolders = b64[len - 2] === '=' ? 2 : b64[len - 1] === '=' ? 1 : 0

  arr = new Arr(len * 3 / 4 - placeHolders)

  l = placeHolders > 0 ? len - 4 : len

  var L = 0

  for (i = 0, j = 0; i < l; i += 4, j += 3) {
    tmp = (revLookup[b64.charCodeAt(i)] << 18) | (revLookup[b64.charCodeAt(i + 1)] << 12) | (revLookup[b64.charCodeAt(i + 2)] << 6) | revLookup[b64.charCodeAt(i + 3)]
    arr[L++] = (tmp >> 16) & 0xFF
    arr[L++] = (tmp >> 8) & 0xFF
    arr[L++] = tmp & 0xFF
  }

  if (placeHolders === 2) {
    tmp = (revLookup[b64.charCodeAt(i)] << 2) | (revLookup[b64.charCodeAt(i + 1)] >> 4)
    arr[L++] = tmp & 0xFF
  } else if (placeHolders === 1) {
    tmp = (revLookup[b64.charCodeAt(i)] << 10) | (revLookup[b64.charCodeAt(i + 1)] << 4) | (revLookup[b64.charCodeAt(i + 2)] >> 2)
    arr[L++] = (tmp >> 8) & 0xFF
    arr[L++] = tmp & 0xFF
  }

  return arr
}

export const fromByteArray = (uint8) => (dispatch) => {
	var tmp
	var len = uint8.length
	var extraBytes = len % 3
	var output = ''
	var parts = []
	var maxChunkLength = 16383

	for (var i = 0, len2 = len - extraBytes; i < len2; i += maxChunkLength) {
		parts.push(encodeChunk(uint8, i, (i + maxChunkLength) > len2 ? len2 : (i + maxChunkLength)))
	}

	if (extraBytes === 1) {
		tmp = uint8[len - 1]
		output += lookup[tmp >> 2]
		output += lookup[(tmp << 4) & 0x3F]
		output += '=='
	} else if (extraBytes === 2) {
		tmp = (uint8[len - 2] << 8) + (uint8[len - 1])
		output += lookup[tmp >> 10]
		output += lookup[(tmp >> 4) & 0x3F]
		output += lookup[(tmp << 2) & 0x3F]
		output += '='
	}

	parts.push(output)

	return parts.join('')
}

export const ArrayToString = (arr) => (dispatch) => {
	var ret = [];
	
	var length = arr.length;
	if (length > 0 && 
		arr[length - 1] === 0) {
		length -= 1;
	}
	
	for (var i = 0; i < length; i++) {
		var chr = arr[i];
		if (chr > 0xFF) {
			chr &= 0xFF;
		}

		ret.push(String.fromCharCode(chr));
	}

	return  decodeURIComponent(escape(ret.join('')));
}

export const StringToArray = (s) => (dispatch) => {
	var utf8 = unescape(encodeURIComponent(s));

	var arr = [];
	for (var i = 0; i < utf8.length; i++) {
		arr.push(utf8.charCodeAt(i));
	}
	return arr;
}

export const UTF16LEArrayToString = (arr) => (dispatch) => {
	var i = 0;
	var ret = '';
	var length;

	if ((arr.length % 2) !== 0)
		return null;

	length = arr.length;
	if (length > 0 && 
		arr[length - 2] === 0 && 
		arr[length - 1] === 0) {
		length -= 2;
	}
		
	while (i < length) {
		ret += String.fromCharCode(arr[i] | (arr[i+1] << 8)); 
		i += 2;
	}

	return ret;
}

export const isSupported = (charset) => {
	if (charset !== "UTF-16LE" && 
		charset !== "UTF-8") {
		return false;
	}

	return true;
}

export const StringToUTF16LEArray = (str, zero) => (dispatch) => {
	var L = [];
	var c;

	for (var i = 0; i < str.length; i++) {
		c = str.charCodeAt(i);
		L.push(c & 0xFF);
		L.push((c & 0xFF00) >> 8);
	}
	
	if (zero) {
		L.push(0);
		L.push(0);
	}
	
	return L;
}

export const init = () => (dispatch) => {
	var code = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
	for (var i = 0, len = code.length; i < len; ++i) {
		lookup[i] = code[i]
		revLookup[code.charCodeAt(i)] = i
	}

	revLookup['-'.charCodeAt(0)] = 62
	revLookup['_'.charCodeAt(0)] = 63
}

function tripletToBase64 (num) {
    return lookup[(num >> 18) & 0x3F] + lookup[(num >> 12) & 0x3F] + lookup[(num >> 6) & 0x3F] + lookup[num & 0x3F]
}

function encodeChunk (uint8, start, end) {
	var tmp
	var output = []
	for (var i = start; i < end; i += 3) {
		tmp = (uint8[i] << 16) + (uint8[i + 1] << 8) + (uint8[i + 2])
		output.push(tripletToBase64(tmp))
	}
	return output.join('')
}

export function cipher (objectJson, publicKeyInput, date) {
	var pki = forge.pki;

	var key = forge.random.getBytesSync(16);
	var iv = forge.util.hexToBytes("00000000000000000000000000000000")
	var cipher = forge.cipher.createCipher('AES-CTR', key);

	var jsonObject = JSON.stringify(objectJson);

	// console.log(jsonObject);

	cipher.start({iv: iv});
	cipher.update(forge.util.createBuffer(jsonObject));
	cipher.finish();

	var encryptedjsonObject = cipher.output;

	// console.log(encryptedjsonObject.toHex());

	// var currentDate = new Date();
	//currentDate.setSeconds(currentDate.getSeconds() + 5);
	// var dateString = currentDate.toISOString().substring(0, 19) + "Z";
	// console.log(dateString);
	// console.log(date);

	var buffer = forge.util.createBuffer(key, 'raw');
	buffer.putBytes(forge.util.encodeUtf8(date));

	var bytes = buffer.getBytes();
	var publicKey = pki.publicKeyFromPem(publicKeyInput);

	var encrypted = publicKey.encrypt(bytes, 'RSA-OAEP', {
	  md: forge.md.sha256.create(),
	  mgf1: {
	    md: forge.md.sha256.create()
	  }
	});
	// console.log(forge.util.bytesToHex(encrypted));
	var data = {
		secretKey: forge.util.encode64(encrypted),
		encryptedData: forge.util.encode64(encryptedjsonObject.getBytes())
	}

	return data;
}

export const checkExtension = (fileName) => (dispatch) => {
	var extensions = ['txt','rtf', 'doc','docx','xls','xlsx', 'ppt', 'pptx', 'odt','ods','pdf', 'jpg', 'jpeg', 'png', 'bmp'];

	var fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;

	fileExtension = fileExtension.toLowerCase();

	if (extensions.indexOf(fileExtension) !== -1) {
		return true;
    } else {
    	return false;
    }
}

export const checkExtensionVerify = (fileName) => (dispatch) => {
	var extensions = ['txt','rtf', 'doc','docx','xls','xlsx', 'ppt', 'pptx', 'odt','ods','pdf', 'jpg', 'jpeg', 'png', 'bmp'];

	var name = fileName.replace(/\.p7s/g, "");

	var fileExtension = name.substring(name.lastIndexOf('.') + 1, name.length) || name;

	fileExtension = fileExtension.toLowerCase();

	if (extensions.indexOf(fileExtension) !== -1) {
		return true;
    } else {
    	return false;
    }
}

export const checkExtensionDecrypt = (fileName) => (dispatch) => {
	var extensions = ['txt','rtf', 'doc','docx','xls','xlsx', 'ppt', 'pptx', 'odt','ods','pdf', 'jpg', 'jpeg', 'png', 'bmp'];

	var name = fileName.replace(/\.p7e/g, "");

	var fileExtension = name.substring(name.lastIndexOf('.') + 1, name.length) || name;

	fileExtension = fileExtension.toLowerCase();

	if (extensions.indexOf(fileExtension) !== -1) {
		return true;
    } else {
    	return false;
    }
}

export const fromBytes = (digit) => (dispatch) => {
	var result,
			gigabyte  = 1073741824,
			megabyte = 1048576,
			kilobyte = 1024

	function div(val, by) {
	    return (val - val % by) / by;
	}

	function isInt(n) {
		return Number(n) === n && n % 1 === 0
	}

	if (div(digit, gigabyte) > 0 && isInt(div(digit, gigabyte))) {
		result = {
			value: (digit / gigabyte).toFixed(2),
			unit: "gigabyte"
		}
	} else if (div(digit, megabyte) > 0 && isInt(div(digit, megabyte))) {
		result = {
			value: (digit / megabyte).toFixed(2),
			unit: "megabyte"
		}
	} else if (div(digit, kilobyte) > 0 && isInt(div(digit, kilobyte))) {
		result = {
			value: (digit / kilobyte).toFixed(2),
			unit: "kilobyte"
		}
	} else {
		result = {
			value: digit,
			unit: "byte"
		}
	}

	return result;
}